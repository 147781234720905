import React, { useEffect, useState } from "react";
import { Helmet } from 'react-helmet';

const TermsConditions = ({metadescription}) => {
  const[storematatag,setStorematatag] = useState("");
  useEffect(()=>{
   const targetSlug = 'terms-and-condition';
   const targetObject = metadescription.find(item => item.slug === targetSlug);
   setStorematatag(targetObject)
  },[metadescription])

  return (
    <>
    {storematatag  &&
      <Helmet>
        <meta name="description" content={storematatag.meta_key_discription} />
        <meta name="keywords" content={storematatag.meta_keyword} />
      </Helmet>}
      <section className="privecy_policy mt-5">
        <div className="container">
          <h1 className="heading_sub_page_for_custome">
            Terms &amp; Conditions
          </h1>
          <div className="privecy_policy_div">
            <h3 className="mt-1 mb-3 main_heading_bar_privecy_policy">
              Acceptance of Terms:
            </h3>

            <p>Welcome to shipparcel.ca!</p>

            <p>
              These terms and conditions outline the rules and regulations for
              the use of shipparcel's Website, shipparcel.ca
              
            </p>

            <p>
              By accessing this website we assume you accept these terms and
              conditions. Do not continue to use shipparcel.ca if you do not
              agree to take all of the terms and conditions stated on this page.
            </p>

            <p>
              By using our parcel shipping services, you agree to abide by these
              terms and conditions. Please read them carefully.
            </p>

            <h3 className="main_heading_bar_privecy_policy">
              Parcel Packaging:
            </h3>

            <p>
              You are responsible for ensuring that your parcel is properly
              packaged to withstand normal handling during transit.
            </p>

            <p>
              We are not liable for any damage to improperly packaged parcels.
            </p>

            <h3 className="main_heading_bar_privecy_policy">
              Shipment Restrictions:
            </h3>

            <p>
              You must comply with all local, national, and international
              shipping regulations.
            </p>
            <p>
              We do not ship hazardous materials, prohibited items, or items of
              high value. Please refer to our shipping guidelines for more
              information.
            </p>

            <h3 className="main_heading_bar_privecy_policy">
              Pickup and Delivery:
            </h3>
            <ul>
              <li>
                We will make all reasonable efforts to pick up and deliver
                parcels as scheduled.
              </li>
              <li>
                Delays may occur due to circumstances beyond our control, such
                as weather, traffic, or customs inspections.
              </li>
            </ul>

            <h3 className="main_heading_bar_privecy_policy">
              Liability and Insurance:
            </h3>

            <ul>
              <li>
                Our liability for lost or damaged parcels is limited to the
                declared value or the actual value, whichever is lower.
              </li>
              <li>
                You may purchase additional insurance for high-value parcels.
              </li>
            </ul>

            <h3 className="main_heading_bar_privecy_policy">Claims:</h3>

            <ul>
              <li>
                All claims for lost or damaged parcels must be submitted in
                writing within [number] days of the delivery date.
              </li>
              <li>
                We may require supporting documentation to process claims.
              </li>
            </ul>

            <h3 className="main_heading_bar_privecy_policy">
              Delivery Verification:
            </h3>

            <ul>
              <li>
                We will provide a delivery confirmation for each parcel,
                including the date and time of delivery.
              </li>
              <li>
                This confirmation is sufficient evidence of successful delivery.
              </li>
            </ul>

            <h3 className="main_heading_bar_privecy_policy">Payment:</h3>
            <ul>
              <li>You are responsible for all shipping charges.</li>
              <li>
                Payment terms are [insert your payment terms, e.g., "prepaid,"
                "collect," "cash on delivery," etc.].
              </li>
            </ul>

            <h3 className="main_heading_bar_privecy_policy">
              Cancellation and Refunds:
            </h3>
            <p>
              Cancellation and refund policies vary depending on the specific
              shipping service selected. Please refer to our website or contact
              our customer service for details.
            </p>

            <h3 className="main_heading_bar_privecy_policy">Governing Law:</h3>
            <p>
              These terms and conditions are governed by the laws of [your
              jurisdiction].
            </p>

            <h3 className="main_heading_bar_privecy_policy">
              Contact Information:
            </h3>

            <ul>
              <li>
                For any questions or concerns regarding our parcel shipping
                services, please contact our customer service at [customer
                service contact information].
              </li>
            </ul>
            <p>
              Please note that this is a general template, and you should
              customize it to meet the specific needs of your shipping business.
              It's also important to have legal counsel review and help you
              finalize your terms and conditions to ensure they are legally
              sound and compliant with relevant regulations in your
              jurisdiction.
            </p>

          </div>
        </div>
      </section>
    </>
  );
};

export default TermsConditions;
