import React, { useEffect, useState } from 'react'
import heroheaderimg1 from '../../../front/assest/img/gallery/hero-header-bg.png'
import HomeService from './HomeService'
import Enquiry from './Enquiry'
import Contactinfo from './Contactinfo'
import Carousel from 'react-multi-carousel';
import { FaArrowRight } from "react-icons/fa";
import {filebasepath} from "../../../Function";
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet';
const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 1,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};

const Home = ({servicedata,datasetting ,setFeaturedata,sliderdata,metadescription}) => {
  const[storematatag,setStorematatag] = useState("");



  useEffect(()=>{
    const targetSlug = 'service';
    const targetObject = metadescription.find(item => item.slug === targetSlug);
    setStorematatag(targetObject)
   },[metadescription])

  return (
    <>
    {storematatag  &&
      <Helmet>
        <meta name="description" content={storematatag.meta_key_discription} />
        <meta name="keywords" content={storematatag.meta_keyword} />
      </Helmet>}
  <Carousel  responsive={responsive} autoPlay={true} infinite={true} showDots={false}  autoPlaySpeed={3000}
  removeArrowOnDeviceType={["mobile", "tablet",""]}
  >

  {sliderdata.map((item,index)=>{
    return(
      <section className="py-xxl-10 pb-0 hero-section" id="home">
    <div
      className="bg-holder bg-size"
      style={{
        backgroundImage: `url(${heroheaderimg1})`,
        backgroundPosition: "top center",
        backgroundSize: "cover"
      }} ></div>
    {/*/.bg-holder*/}
    <div className="container">
      <div className="row align-items-center">
        <div className="col-md-5 col-xl-6 col-xxl-7 order-0 order-md-1 text-end">
        {item.image ? 
          <img
            className="pt-7 pt-md-0 w-100"
            src={filebasepath + item.image }
            alt="hero-header"
          />
        :""}

        </div>
        <div className="col-md-75 col-xl-6 col-xxl-5 text-md-start text-center py-8 text-box">
          <h1 className="fw-normal fs-6 fs-xxl-7 hero-inner-heading _carosel_home_to">
          {item.title}{" "}
          </h1>
          <p className="fs-1 mb-5 para_carosel_home_to">
            {item.discription}
          </p>
          <Link className="btn btn-primary me-2" to={item.link} role="button">
            {item.button}
            <FaArrowRight className='aerow_right'/>
          </Link>
        </div>
      </div>
    </div>
  </section>
    )
  })}
  </Carousel>

  <HomeService 
    isFeatured={true} 
    servicedata={servicedata}
    setFeaturedata={setFeaturedata} 
    service_top_rep={'service_top_rep'}
   />
 <Enquiry datasetting={datasetting}/>
 <Contactinfo datasetting={datasetting}/>

    </>
  )
}

export default Home
