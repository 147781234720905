import React, { useEffect, useState } from "react";
import { Helmet } from 'react-helmet';
const Privacypolicy = ({metadescription}) => {
  const[storematatag,setStorematatag] = useState("");


  useEffect(()=>{
    const targetSlug = 'privacy-and-policy';
    const targetObject = metadescription.find(item => item.slug === targetSlug);
    setStorematatag(targetObject)
   },[metadescription])
  return (
    <>

    {storematatag  &&
      <Helmet>
        <meta name="description" content={storematatag.meta_key_discription} />
        <meta name="keywords" content={storematatag.meta_keyword} />
      </Helmet>}

      <section className="privecy_policy mt-5">
        <div className="container">
          <h1 className="heading_sub_page_for_custome">Privacy Policy</h1>
          <div className="privecy_policy_div">
            <h3 className="mt-1 mb-3 main_heading_bar_privecy_policy">
              Introduction:
            </h3>
            <p>
              shipparcel is committed to protecting the privacy and security of
              your personal information. This privacy policy explains how we
              collect, use, and safeguard the information you provide to us when
              using our parcel shipping services.
            </p>

            <h3 className="main_heading_bar_privecy_policy">
              Information We Collect:
            </h3>
            <p>We may collect the following types of information:</p>

            <ul>
              <li>
                Personal Information: Name, contact information (address, phone
                number, email), and government-issued identification when
                required by law.
              </li>
              <li>
                Parcel Information: Tracking numbers, shipment details, contents
                of parcels.
              </li>
              <li>
                Payment Information: Billing and payment information, including
                credit card details.
              </li>
              <li>
                Usage Information: Information about your interactions with our
                website and mobile applications.
              </li>
            </ul>

            <h3 className="main_heading_bar_privecy_policy">
              How We Use Your Information:
            </h3>
            <p>We use your information for the following purposes:</p>

            <ul>
              <li>
                To provide our parcel shipping services and fulfill your
                shipments.
              </li>
              <li>
                To communicate with you regarding your shipments and provide
                customer support.
              </li>
              <li>To process payments and prevent fraud.</li>
              <li>To improve our services and website.</li>
              <li>To comply with legal requirements and regulations.</li>
            </ul>

            <h3 className="main_heading_bar_privecy_policy">
              Sharing Your Information:
            </h3>
            <p>
              We may share your information with third parties under the
              following circumstances:
            </p>

            <ul>
              <li>
                With service providers and partners who assist us in providing
                our services.
              </li>
              <li>
                To comply with legal obligations or respond to government
                requests.
              </li>
              <li>
                In connection with the sale or transfer of our business or
                assets.
              </li>
              <li>With your consent.</li>
            </ul>

            <h3 className="main_heading_bar_privecy_policy">Security:</h3>
            <p>
              We implement industry-standard security measures to protect your
              information from unauthorized access, disclosure, or alteration.
              However, no data transmission over the internet is entirely
              secure, and we cannot guarantee the security of your information.
            </p>

            <h3 className="main_heading_bar_privecy_policy">Your Rights:</h3>
            <p>
              You have certain rights regarding your personal information,
              including the right to access, correct, delete, or restrict the
              processing of your data. If you have any questions or requests
              related to your data, please contact us using the information
              provided in Section 8.
            </p>

            <h3 className="main_heading_bar_privecy_policy">
              {" "}
              Cookies and Tracking Technologies:
            </h3>
            <p>
              We use cookies and similar technologies to enhance your experience
              on our website. You can manage your cookie preferences through
              your browser settings.
            </p>

            <h3 className="main_heading_bar_privecy_policy"> Contact Us:</h3>
            <p>
              If you have any questions, concerns, or requests related to this
              privacy policy, please contact us:
            </p>

            <h3 className="main_heading_bar_privecy_policy">
              {" "}
              Changes to the Privacy Policy:
            </h3>
            <p>
              We may update this privacy policy from time to time to reflect
              changes in our practices or for legal reasons. The effective date
              will be updated when changes are made. We encourage you to review
              this policy periodically.
            </p>

            <p>
              By using our parcel shipping services, you consent to the terms of
              this privacy policy. If you do not agree with the terms outlined
              in this policy, please do not use our services.
            </p>

            <p>
              Again, please remember that this is a general template. You should
              customize it to fit your specific business practices and consult
              with legal counsel to ensure compliance with relevant privacy laws
              and regulations.
            </p>
          </div>
        </div>
      </section>
    </>
  );
};

export default Privacypolicy;
