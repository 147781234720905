import React, { useState } from 'react';
import enqueryimg from '../../../front/assest/img/illustrations/callback.png'
import axios from 'axios';
import { apibaseURl,showErrorToast} from "../../../Function";

const Enquiry = ({datasetting}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [successms, setSuccessms] = useState("");
  const [inputs, setInputs] = useState({
    name: '',
    email: '',
    message: ''
  });

  const [errors, setErrors] = useState({
    name: '',
    email: '',
    message: ''
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const enquirydata = {
      name: inputs.name,
      email: inputs.email,
      message: inputs.message,
    };
    setErrors("")
    try {
      setIsLoading(true);
      const response = await axios.post(`${apibaseURl}addenquiry`, enquirydata);
      if (response.status === 200) {
        if (response?.data?.status === true) {
          setSuccessms(response?.data?.msg)
          setInputs({
            name: '',
            email: '',
            message: ''
          });
          setTimeout(()=>{
            setSuccessms("")
          },2000)
        } else {
          if (response?.data?.error) {
            const errorMessages = getValidationErrors(response?.data?.error);
            setErrors(errorMessages);
          } else {
            showErrorToast('General Error', response?.data?.msg);
          }
        }
      }
    }catch (error) {
    
    }finally {
      setIsLoading(false); 
    }
  };

  const getValidationErrors = (errors) => {
    let errorMessages = {
      name: '',
      email: '',
      message: ''
    };
    
    for (const key in errors) {
      if (errors.hasOwnProperty(key)) {
        errorMessages[key] = errors[key].join(', ');
      }
    }
    return errorMessages;
  };

  return (
    <>
      <section className='enquiry_to_contact_info'>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-6 col-lg-5 col-xl-4 ">
              <img src={enqueryimg} alt="..." />
              <h5 className="text-danger">REQUEST A CALLBACK</h5>
              <h2>We will contact in the shortest time.</h2>
                {datasetting.hoursOperation && (
                      datasetting.hoursOperation.map((item,index)=>{
                        return(
                          <li className="text-muted muted_text_write">
                            <span>{item.day_name}</span> &nbsp; <span>{item.day_time}</span>
                          </li>
                        )
                      })
                    )}
            </div>
            <div className="col-md-6 col-lg-5 col-xl-4 mx_bar_to">
                {successms ? 
                    <div class="alert alert-success" role="alert">
                        {successms} 
                      </div>
                :""}
              <form className="row" onSubmit={handleSubmit}>
                <div className="mb-2">
                  <label className="form-label visually-hidden" htmlFor="inputName">
                    Name
                  </label>
                  <input
                    className="form-control form-quriar-control"
                    id="inputName"
                    type="text"
                    placeholder="Name"
                    name="name"
                    value={inputs.name}
                    onChange={handleChange}
                  />
                  <p className="text-danger">{errors.name}</p>
                </div>
                <div className="mb-2">
                  <label className="form-label visually-hidden" htmlFor="inputEmail">
                    Another label
                  </label>
                  <input
                    className="form-control form-quriar-control"
                    id="inputEmail"
                    type="email"
                    placeholder="Email"
                    name="email"
                    value={inputs.email}
                    onChange={handleChange}
                  />
                  <p className="text-danger">{errors.email}</p>
                </div>
                <div className="mb-2">
                  <label
                    className="form-label visually-hidden">
                    Message
                  </label>
                  <textarea
                    className="form-control form-quriar-control border-400"
                    id="validationTextarea"
                    style={{ height: 150 }}
                    defaultValue={""}
                    name="message"
                    value={inputs.message}
                    placeholder="Message"
                    onChange={handleChange}
                  />
                  <p className="text-danger">{errors.message}</p>
                </div>
                <div className="d-grid">
                <button className="btn btn-primary" type="submit" disabled={isLoading}>
                  {isLoading ? 'Sending...' : 'Send Message'}
               </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Enquiry;
