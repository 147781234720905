import React, { useState, useEffect } from "react";
import { apibaseURl, showSuccessToast, fileupload, filebasepath } from "../../Function";
import axios from "axios";
import Swal from 'sweetalert2'

const Setting = () => {
  const [data, setdata] = useState({});
  const [imagelogo, setImagelogo] = useState();
  const [imagefavicon, setImagefavicon] = useState();
  const [dayName, setDayName] = useState('');
  const [dayTime, setDayTime] = useState('');
  const [hoursOperation, setHoursOperation] = useState([]);


  const addHourOperation = () => {
    if (dayName && dayTime) {
      setHoursOperation([...hoursOperation, { day_name: dayName, day_time: dayTime }]);
      setDayName('');
      setDayTime('');
    }
    var test = [...hoursOperation, { day_name: dayName, day_time: dayTime }];
   
    setdata(prevData => ({
      ...prevData,
      hoursOperation: test  
    }));
  };

  const updateHourOperation = (index, field, value) => {
    const newHoursOperation = hoursOperation.map((hour, i) =>
      i === index ? { ...hour, [field]: value } : hour
    );
    setdata(prevData => ({
      ...prevData,
      hoursOperation: newHoursOperation  
    }));
    setHoursOperation(newHoursOperation);
  };

  const deleteHourOperation = (indexGet) => {
    const newHoursOperation = hoursOperation.filter((item, index) => index !== indexGet);
    setdata(prevData => ({
      ...prevData,
      hoursOperation: newHoursOperation  
    }));
    setHoursOperation(newHoursOperation);
  };

  const handleDayNameChange = (e) => {
    setDayName(e.target.value);
  };

  const handleDayTimeChange = (e) => {
    setDayTime(e.target.value);
  };

  // image upload api in ===============>
  const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("image", file);

    try {
      const response = await axios.post(`${fileupload}addimage`, formData);
      if (response.status == 200) {
        if (response?.data.status == true) {
          if (e.target.name === "site_logo") {
            setImagelogo(response.data.data.image);
            setdata((prevData) => ({
              ...prevData,
              'site_logo': response.data.data.image,
            }));
          } else if (e.target.name === "site_icon") {
            setImagefavicon(response.data.data.image);
            setdata((prevData) => ({
              ...prevData,
              'site_icon': response.data.data.image,
            }));
          }
        }
      } else {
      }
    } catch (error) {
    }
  };

  // deata show api =======================>
  const fetchsettingdata = async () => {
    try {
      const response = await axios.get(`${apibaseURl}settings`)
      if (response.status == 200) {
        if (response?.data.status == true) {
          
          setdata(response.data.data);
          setHoursOperation(response.data.data.hoursOperation)
        }
      }
    } catch (error) {
    }
  };
  useEffect(() => {
   
    fetchsettingdata();
  }, []);


  // data update api ============================>

    const handleInputChange = (e) => {
      const { name, value } = e.target;
      setdata((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    };
  

    // Submit form data
    const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${apibaseURl}editsetting`, data)

      if (response.status === 200) {
     
        if (response?.data.status == true) {
          showSuccessToast(response?.data.msg)
          fetchsettingdata();
        }
      } else {
      }
    } catch (error) {
    }
  };
  // delete image api call for setting ==========================>
  const handledeleteimageforlogo = () => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        const connectapi = deleteApiCallforlogo()
        connectapi.then((responses) => {
          if (responses.status == 200) {
            if (responses?.data?.status == true) {
              Swal.fire(
                'Deleted!',
                'Your file has been deleted.',
                'success'
              )
              fetchsettingdata()
            } else {
              Swal.fire(
                'Opps Something Wrong?',
                'That thing is still around?',
                'question'
              )
            }

          }
        })
      }
    })
  }

  const deleteApiCallforlogo = async () => {

    const responses = await axios.post(`${apibaseURl}setting/delete/site_logo`);
    return responses;
  }
  // delete image api call for setting ==========================>
  const handledeleteimageforfavicon = () => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        const connectapi = deleteApiCallforfavicon()
        connectapi.then((responses) => {
          if (responses.status == 200) {
            if (responses?.data?.status == true) {
              Swal.fire(
                'Deleted!',
                'Your file has been deleted.',
                'success'
              )
              fetchsettingdata()
            } else {
              Swal.fire(
                'Opps Something Wrong?',
                'That thing is still around?',
                'question'
              )
            }

          }
        })
      }
    })
  }

  const deleteApiCallforfavicon = async () => {
    const responses = await axios.post(`${apibaseURl}setting/delete/site_icon`);
    return responses;
  }



  return (
    <>
      <div class="container-xxl flex-grow-1 container-p-y">
        <div class="row">
          <div class="col-md-12">
            <div class="card mb-4">
              <h5 className="card-header">Settings</h5>

              <div className="card-body">
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="form-group col-md-12 mb-3">
                      <label>Site Titile</label>
                      <input
                        type="text"
                        className="form-control"
                        name="site_title"
                        placeholder="Site Titile"
                        value={data.site_title}
                        onChange={handleInputChange}
                      />
                    </div>

                    <div className="form-group col-md-6 mb-3">
                      <label>Site Logo</label><br></br>
                      {data.site_logo ?
                        <div className='image_wapre_div_for_data_image'>
                          <img src={`${filebasepath}${data.site_logo}`} alt="img" width="100px" className="mb-2" />
                          <i class="fa fa-times bar_second_image_top" aria-hidden="true" onClick={handledeleteimageforlogo}></i>
                        </div>
                        : ""
                      }

                      <input
                        type="file"
                        className="form-control"
                        name="site_logo"
                        onChange={handleFileUpload}
                      />
                    </div>
                    <div className="form-group col-md-6 mb-3">
                      <label>Site Favicon</label><br></br>
                      {data.site_icon ?
                        <div className='image_wapre_div_for_data_image'>
                          <img src={`${filebasepath}${data.site_icon}`} alt="img" width="50px" className="mb-2" />
                          <i class="fa fa-times bar_second_image_top" aria-hidden="true" onClick={handledeleteimageforfavicon}></i>
                        </div>
                        : ""
                      }

                      <input
                        type="file"
                        className="form-control"
                        name="site_icon"
                        onChange={handleFileUpload}
                      />
                    </div>
                    <div className="form-group col-md-6 mb-3">
                      <label>Admin Email</label>
                      <input
                        type="text"
                        className="form-control"
                        name="admin_email"
                        value={data.admin_email}
                        onChange={handleInputChange}
                      />
                    </div>

                    <div className="form-group col-md-6 mb-3">
                      <label>Admin Email Second</label>
                      <input
                        type="text"
                        className="form-control"
                        name="admin_email_two"
                        value={data.admin_email_two}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="form-group col-md-6 mb-3">
                      <label>Admin Phone</label>
                      <input
                        type="tel"
                        className="form-control"
                        name="admin_phone"
                        value={data.admin_phone}
                        onChange={handleInputChange}
                      />
                    </div>

                    <div className="form-group col-md-6 mb-3">
                      <label>Admin Phone Second</label>
                      <input
                        type="tel"
                        className="form-control"
                        name="admin_phone_two"
                        value={data.admin_phone_two}
                        onChange={handleInputChange}
                      />
                    </div>

                    <div className="form-group col-md-6 mb-3">
                      <label>Admin Fax</label>
                      <input
                        type="tel"
                        className="form-control"
                        name="fax_number_one"
                        value={data.fax_number_one}
                        onChange={handleInputChange}
                      />
                    </div>


                    <div className="form-group col-md-6 mb-3">
                      <label>Admin Fax Second</label>
                      <input
                        type="tel"
                        className="form-control"
                        name="fax_number_two"
                        value={data.fax_number_two}
                        onChange={handleInputChange}
                      />
                    </div>


                    <div className="form-group col-md-6 mb-3">
                      <label>Admin Web</label>
                      <input
                        type="text"
                        className="form-control"
                        name="web_link_one"
                        value={data.web_link_one}
                        onChange={handleInputChange}
                      />
                    </div>


                    <div className="form-group col-md-6 mb-3">
                      <label>Admin Web Second</label>
                      <input
                        type="text"
                        className="form-control"
                        name="web_link_two"
                        value={data.web_link_two}
                        onChange={handleInputChange}
                      />
                    </div>


                    <div className="form-group col-md-12 mb-3">
                      <label>Admin Address</label>
                      <textarea
                        className="form-control"
                        row="5"
                        col="12"
                        id="comment"
                        name="address"
                        value={data.address}
                        onChange={handleInputChange}
                      >
                        Address
                      </textarea>
                    </div>

                    <div className="form-group col-md-6 mb-3">
                      <label>Facebook Icon</label>
                      <input
                        type="text"
                        className="form-control"
                        name="fb_icon"
                        value={data.fb_icon}
                        onChange={handleInputChange}
                      />
                    </div>

                    <div className="form-group col-md-6 mb-3">
                      <label>Youtub Icon</label>
                      <input
                        type="text"
                        className="form-control"
                        name="yt_icon"
                        value={data.yt_icon}
                        onChange={handleInputChange}
                      />
                    </div>

                    <div className="form-group col-md-6 mb-3">
                      <label>Instagram Icon</label>
                      <input
                        type="text"
                        className="form-control"
                        name="ig_icon"
                        value={data.ig_icon}
                        onChange={handleInputChange}
                      />
                    </div>

                    <div className="form-group col-md-6 mb-3">
                      <label>linkedin Icon</label>
                      <input
                        type="text"
                        className="form-control"
                        name="in_icon"
                        value={data.in_icon}
                        onChange={handleInputChange}
                      />
                    </div>

                    <div className="form-group row">
                      <label>Hours of Operation</label>
                      <div className="col-md-5 mt-1">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Day Name"
                          value={dayName}
                          onChange={handleDayNameChange}
                        />
                      </div>
                      <div className="col-md-5 mt-1">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Day Time"
                          value={dayTime}
                          onChange={handleDayTimeChange}
                        />
                      </div>
                      <div className="col-md-2 mt-1">
                          <button onClick={addHourOperation} type="button" className="Add_todo_list_button">
                             <i class="fa fa-plus" aria-hidden="true"></i>
                          </button>
                      </div>

                      <div className="col-md-12 mt-4">
                          {hoursOperation.map((hour, index) => (
                            <div key={index} className="row mb-2">
                              <div className="col-md-5">
                                <input
                                  type="text"
                                  className="form-control"
                                  value={hour.day_name}
                                  onChange={(e) => updateHourOperation(index, 'day_name', e.target.value)}
                                  style={{ marginRight: '10px' }}
                                />
                              </div>

                              <div className="col-md-5">
                              <input
                                type="text"
                                className="form-control"
                                value={hour.day_time}
                                onChange={(e) => updateHourOperation(index, 'day_time', e.target.value)}
                                style={{ marginRight: '10px' }}
                              />
                              </div>
                              <div className="col-md-2">
                                 <button onClick={() => deleteHourOperation(index)} type="button" className="todo_list_button_wrape">
                                  <i class="fa fa-trash-o" aria-hidden="true"></i>
                                  </button>
                              </div>
                            </div>
                          ))}
                  
                      </div>

                    </div>

                    <div className="card-footer">
                      <button className="btn btn-primary" type="submit">Submit</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Setting;


