import React, { useState } from 'react';
import { apibaseURl, showSuccessToast, showErrorToast, fileupload,filebasepath} from "../../../Function";
import { Link, useNavigate } from 'react-router-dom';
import { IoMdArrowRoundBack } from 'react-icons/io';
import axios from 'axios';

const Addtestimonial = () => {
  const [inputs, setInputs] = useState({
    title: '',
    descrip: '',
    rating:'',
    name:'',
    designation:'',
    status: '',
  });
  const [image, setImage] = useState('');
  const navigate = useNavigate();



  // image upload api in ==================================> 
  const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("image", file);

    try {
      const response = await axios.post(`${fileupload}addimage`, formData);
 
      if (response.status === 200 && response.data.status === true) {
        setImage(response.data.data.image);
        showSuccessToast(response?.data?.msg);
      }
    } catch (error) {
  
    }
  };

  // data insert in  api ==============>

  const handleChange = (event) => {
    const { name, value } = event.target;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const servicedata = {
      title: inputs.title,
      image: image,
      descrip: inputs.descrip,
      rating:inputs.rating,
      name:inputs.name,
      designation:inputs.designation,
      status: inputs.status,
    };
    try {
      const response = await axios.post(`${apibaseURl}addtestimonial`, servicedata);

      if (response.status === 200) {
        if (response?.data?.status === true) {
          showSuccessToast(response?.data?.msg);
           navigate('/admin/admintestimonial')
        } else {
          if (response?.data?.error) {
            const errorMessages = getValidationErrors(response?.data?.error);
            showErrorToast(errorMessages);
          } else {
            showErrorToast('General Error', response?.data?.msg);
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getValidationErrors = (errors) => {
    let errorMessages = '';
    for (const key in errors) {
      if (errors.hasOwnProperty(key)) {
        //errorMessages += `${key}: ${errors[key][0]}\n`; 
        errorMessages += `${errors[key].join(', ')}\n`
      }
    }
    return errorMessages;
  };

  return (
    <>
        <div class="container-xxl flex-grow-1 container-p-y">
             <div class="row">
                <div class="col-md-12">
                  <div class="card mb-4">
                    <div className="data_acrd_wrapper">
                        <h5 className="card-header">Add Testimonial</h5>
                        <Link to="/admin/admintestimonial" class="btn btn-primary primary_ya"><span className='class_plus'><IoMdArrowRoundBack/></span>Back</Link>
                    </div>
                    <div className='add_divider'> <hr/></div>
                   
                    <div class="card-body">
                     <form onSubmit={handleSubmit}>
                    <div className='mb-3'>
                        <label  className="form-label">Name</label>
                        <input
                          type="text"
                          class="form-control"
                          id="defaultFormControlInput" 
                           placeholder="Name" 
                            name="name"
                            value={inputs.name}
                            onChange={handleChange}
                           />
                      </div>

                      <div className='mb-3'>
                        <label  className="form-label">Testimonial Title</label>
                        <input
                          type="text"
                          class="form-control"
                          id="defaultFormControlInput" 
                           placeholder="Testimonial Title"
                           name="title"
                           value={inputs.title}
                            onChange={handleChange} />
                      </div>
               
                      <div className='mb-3'>
                        <label  className="form-label">Testimonial description</label>
                        <input
                          type="text"
                          class="form-control"
                          id="defaultFormControlInput" 
                           placeholder="Testimonial description"
                            name="descrip"
                            value={inputs.descrip}
                            onChange={handleChange} />
                      </div>


                      <div className='mb-3'>
                        <label  className="form-label">Testimonial Rating</label>
                        <input
                          type="text"
                          class="form-control"
                          id="defaultFormControlInput" 
                           placeholder="Testimonial Rating" 
                           name="rating"
                           value={inputs.rating}
                           onChange={handleChange} />
                      </div>
                      

                      <div className='mb-3'>
                        <label  className="form-label">Testimonial  Designation</label>
                        <input
                           type="text"
                           class="form-control"
                           id="defaultFormControlInput" 
                           placeholder="Testimonial  Designation"
                           name="designation"
                           value={inputs.designation}
                           onChange={handleChange}
                            />
                      </div>
                
                      <div className='mb-3'>
                        <label for="exampleFormControlSelect1" class="form-label">Testimonial Status</label>
                        <select class="form-select"
                         id="exampleFormControlSelect1"
                          aria-label="Default select example"
                           name="status"
                           value={inputs.status}
                           onChange={handleChange}
                          >
                          <option selected>select Status</option>
                          <option value="active">Active</option>
                          <option value="inactive">Inactive</option>
                        </select>
                      </div>

                       <div class="mb-3">
                        <label for="formFileMultiple" class="form-label">Testimonial image</label>
                        <br/>
                        {image ? 
                        <img src={filebasepath + image} alt="Avatar" className="rounded-circle "  width="50px" /> 
                         : ""}
                        <input className="form-control" type="file" id="formFileMultiple" 
                         name="filename"
                         onChange={handleFileUpload} />
                      </div>


                      <div className="mb-3 text-center">
                        <button type="submit" className="btn btn-primary">
                          submit
                        </button>
                      </div>
                      
                     </form>

                    </div>
                  </div>
                </div>
              </div>
         </div>
    </>
  )
}

export default Addtestimonial
