import React,{Suspense, useState} from 'react'
import { showErrorToast, showSuccessToast,apibaseURl,getValidationErrors} from '../../Function';
import { Link, useNavigate } from 'react-router-dom';
import logoimg from '../../admin/assest/img/logo/logo.png'
import axios from 'axios';

const AdminCss = React.lazy(()=>import("../../admin/component/layoute/AdminCss"));


const AdminLogin = () => {
  const navigate= useNavigate()
  const [data, setData] = useState({
    email: "",
    password: ""
  });

  const handleChange = (e) => {
    const value = e.target.value;
    setData({
      ...data,
      [e.target.name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const userData = {
      email: data.email,
      password: data.password
    };
    try{
     const responce = await axios.post(`${apibaseURl}userlogin`, userData);
     if(responce?.status == 200 && responce?.data.status==true){
      setData(responce?.data.data)
      showSuccessToast(responce?.data.msg);
      const userDataToStore = {
        user: responce?.data.data
      };
       localStorage.setItem('user', JSON.stringify(userDataToStore));
        navigate('/admin/dashboard')
     }else {
      if (responce?.data?.error) {
        const errorMessages = getValidationErrors(responce?.data?.error);
        showErrorToast(errorMessages);
      } else {
        showErrorToast(responce?.data?.msg);
      }
    }
    }catch(error){
    
    }

  };



  return (
    <>
   <Suspense fallback={<div>Loading...</div>}>
    <AdminCss/>
     <div className="container-xxl">
     <div className="authentication-wrapper authentication-basic container-p-y">
     <div className="authentication-inner">
      <div className="card card-primary">
        <div className="card-body">
          <div className="app-brand justify-content-center">
            <Link to="#" className="app-brand-link gap-2">
              <span className="app-brand-text demo text-body fw-bolder">
              <img src={logoimg} alt='img' width="100px" />
              </span>
            </Link>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <label htmlFor="email" className="form-label label_decode">
                Email 
              </label>
              <input
                type="text"
                className="form-control"
                id="email"
                placeholder="Enter your email or username"
                name="email"
                value={data.email}
                onChange={handleChange}
              />
            </div>
            <div className="mb-3 form-password-toggle">
              <div className="d-flex justify-content-between">
                <label className="form-label label_decode" htmlFor="password">
                  Password
                </label>
                {/* <Link href="auth-forgot-password-basic.html">
                  <small>Forgot Password?</small>
                </Link> */}
              </div>
              <div className="input-group input-group-merge">
                <input
                  type="password"
                  id="password"
                  className="form-control"
                  placeholder="············"
                  name="password"
                  value={data.password}
                  onChange={handleChange}
                />
                <span className="input-group-text cursor-pointer">
                  <i className="bx bx-hide" />
                </span>
              </div>
            </div> 
            <div className="mb-3">
              <button className="btn btn-primary d-grid w-100" type="submit">
                Sign in
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
    </div>
   </Suspense>
    </>
  )
}

export default AdminLogin;
