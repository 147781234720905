import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet';
import aboutimg1 from '../../front/assest/img/gallery/about-us-img.png'
import aboutimg2 from '../../front/assest/img/gallery/about-img-2.png'

const About = ({metadescription}) => {
 const[storematatag,setStorematatag] = useState("");
 useEffect(()=>{
  const targetSlug = 'about';
  const targetObject = metadescription.find(item => item.slug === targetSlug);
  setStorematatag(targetObject)
 },[metadescription])

  return (
    <>
    {storematatag  &&
      <Helmet>
        <meta name="description" content={storematatag.meta_key_discription} />
        <meta name="keywords" content={storematatag.meta_keyword} />
      </Helmet>}


        <section className="py-7 about-us" container-xl="container-xl">
          <div className="container">
            <div className="row justify-content-center ">
              <div className="col-md-8 col-lg-5 text-center mb-3">
                <h5 className="text-danger inner-heading pt-5">Find Us</h5>
                <h2 className="">About Us</h2>
              </div>
            </div>
            <div className="row h-100 justify-content-center align-items-center">
              <div className="col-md-6 col-12 pt-4 px-md-2 px-lg-3">
                <div className="img-box">
                  <img
                    className="img-fluid"
                    src={aboutimg1}
                    alt="about-us"
                  />
                </div>
              </div>
              <div className="col-md-6 col-12 pt-4 px-md-2 px-lg-3">
                <h5 className="inner-heading">About Our Company</h5>
                <h2 className="about-us-heading">
                  Trusted Logistic Service Provider
                </h2>
                <ul className="order-list primary-color mrb-40">
                  <li>revolutionary catalysts for chang</li>
                  <li>catalysts for chang the Seamlessly</li>
                  <li>business applications through</li>
                  <li>procedures whereas processes</li>
                </ul>
                <p className="mrb-40">
                  Distinctively exploit optimal alignments for intuitive business
                  applications through revolutionary catalysts for chang the
                  Seamlessly optimal optimal alignments for intuitive.
                </p>
              </div>
            </div>
          </div>

         </section>
         <section className="py-0 about-us about_bar_top_4" container-xl="container-xl">
          <div className="container">
            <div className="row h-100 justify-content-center align-items-center">
              <div className="col-md-6 col-12 pt-4 px-md-2 px-lg-3 order-2 order-lg-1">
                <h5 className="inner-heading">Why Choose Us?</h5>
                <h2 className="about-us-heading">
                  We Deliver your goods Very Fast &amp; Secure
                </h2>
                <p className="mrb-40">
                  Distinctively exploit optimal alignments for intuitive. Quickly
                  coordinate business applications through revolutionary cataly
                  technologies rather than development optimal alignments for
                  intuitive business applications through.
                </p>
              </div>
              <div className="col-md-6 col-12 pt-4 px-md-2 px-lg-3 order-1 order-lg-2">
                <div className="img-box">
                  <img
                    className="img-fluid"
                    src={aboutimg2}
                    alt="about-us"
                  />
                </div>
              </div>
            </div>
          </div>
          {/* end of .container*/}
        </section>
    </>
  )
}

export default About;
