import React, { useEffect, useState } from 'react';
import { apibaseURl, showSuccessToast, showErrorToast, fileupload ,filebasepath} from "../../../Function";
import { Link, useNavigate, useParams } from 'react-router-dom';
import { IoMdArrowRoundBack } from 'react-icons/io';
import axios from 'axios';
import Swal from 'sweetalert2'

const EditSlider = () => {

  const [inputs, setInputs] = useState({
    title: 'A trusted provider',
    discription: '',
    button: '',
    link: '',
    sort: "0",
    status: '',
  });

  const [image, setImage] = useState('');
  const [progressCount, setprogressCount] = useState(0);
  const { id } = useParams();
  const navigate = useNavigate();
  // image upload api in ==================================> 
  const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("image", file);
    try {
      const response = await axios.post(`${fileupload}addimage`, formData,{
        onUploadProgress: (data) => {
          const percentCompletedto = Math.round((data.loaded/data.total)*100);
          setprogressCount(percentCompletedto);
          if (percentCompletedto == 100) {
            setTimeout(()=>{
            setprogressCount(0);
          },4000)
          }
        },
      });
      if (response.status === 200 && response.data.status === true) {
        setImage(response.data.data.image);
        showSuccessToast(response?.data?.msg);
      }
    } catch (error) {
 
    }
  };

 // data show  api in ==================================> 
 const handleshowslider = async () => {
    try {
      const response = await axios.get(`${apibaseURl}slider/${id}`);
      if (response.status === 200 && response.data.status === true) {
        setInputs({ ...response.data.data });
        setImage(response.data.data.image);
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  useEffect(() => {
    handleshowslider();
  }, [id]);

  // data insert in  api ==============>
  const handleUpdatesubmit = async (e) => {
    e.preventDefault();
    const userdata = {
        title: inputs.title,
        image: image,
        discription: inputs.discription,
        button: inputs.button,
        link: inputs.link,
        sort: inputs.sort,
        status: inputs.status,
      id: id
    };
    try {
      const response = await axios.post(`${apibaseURl}editslider`, userdata);
      if (response.status === 200 && response.data.status === true) {
        showSuccessToast(response.data.msg);
        navigate("/admin/sliderfor");
      } else {
        showErrorToast(response.data.error);
      }
    } catch (error) {
    }
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInputs({ ...inputs, [name]: value });
  };

  // delete image api  ===============================>

  const handledeleteimage =(id)=>{
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        const connectapi = deleteApiCall(id)
        connectapi.then((responses)=>{
         if(responses.status==200){
          if(responses?.data?.status == true){
            Swal.fire(
              'Deleted!',
              'Your file has been deleted.',
              'success'
            )
            handleshowslider();
          }else{
            Swal.fire(
              'Opps Something Wrong?',
              'That thing is still around?',
              'question'
            )
          }
         
         }
        })
      }
    })
   }

   const deleteApiCall = async (id) => {
     const responses = await axios.post(`${apibaseURl}slider/delete/image/${id}`);
     return responses;

   }

  return (
    <>
      <div className="container-xxl flex-grow-1 container-p-y">
        <div className="row">
          <div className="col-md-12">
            <div className="card mb-4">
              <div className="data_acrd_wrapper">
                <h5 className="card-header">Edit Slider</h5>
                <Link to="/admin/sliderfor" className="btn btn-primary primary_ya">
                  <span className="class_plus">
                    <IoMdArrowRoundBack />
                  </span>
                  Back
                </Link>
              </div>
              <div className="add_divider">
                {' '}
                <hr />
              </div>

              <div className="card-body">
                <form onSubmit={handleUpdatesubmit}>
                  <div className="mb-3">
                    <label className="form-label">Slider Title</label>
                    <input
                      type="text"
                      className="form-control"
                      id="defaultFormControlInput"
                      placeholder="Slider Title"
                      name="title"
                      value={inputs.title}
                      onChange={handleInputChange}
                    />
                  </div>

                  <div className="mb-3">
                    <label htmlFor="formFileMultiple" className="form-label">
                    Slider Image
                    </label>
                    <br/>
                      {image ?  
                       <div className='image_wapre_div_for_data_image'>
                          <img src={filebasepath + image} alt="Avatar" className=""  width="100px" /> 
                          <i class="fa fa-times bar_second_image_top" aria-hidden="true" onClick={()=>{handledeleteimage(id)}}></i>
                      </div>
                    :""
                    }
                    <input className="form-control mt-1"
                     type="file" 
                     id="formFileMultiple1" 
                     name="serviceicon"
                     onChange={handleFileUpload} />
                    {progressCount > 0 && (
                      <div class="progress mt-2">
                        <div
                          class="progress-bar progress-bar-striped bg-danger progress-bar-animated progress_bar_height_decode"
                          role="progressbar"
                          aria-valuenow={progressCount}
                          aria-valuemin="0"
                          aria-valuemax="100"
                          style={{ width: `${progressCount}%` }}
                        >{`${progressCount}%`}</div>
                      </div>
                    )}
                  </div>

                  <div className="mb-3">
                    <label className="form-label">Slider Description</label>
                    <input
                      type="text"
                      className="form-control"
                      id="defaultFormControlInput"
                      placeholder="Slider Description"
                      name="discription"
                      value={inputs.discription}
                      onChange={handleInputChange}
                    />
                  </div>



                  <div className="mb-3">
                    <label className="form-label">Slider button</label>
                    <input
                      type="text"
                      className="form-control"
                      id="defaultFormControlInput"
                      placeholder="Slider button"
                      name="button"
                      value={inputs.button}
                      onChange={handleInputChange}
                    />
                  </div>



                  <div className="mb-3">
                    <label className="form-label">Slider Button Link</label>
                    <input
                      type="text"
                      className="form-control"
                      id="defaultFormControlInput"
                      placeholder="Slider Button Link"
                      name="link"
                      value={inputs.link}
                      onChange={handleInputChange}
                    />
                  </div>

                  <div className="mb-3">
                    <label className="form-label">Slider sort</label>
                    <input
                      type="number"
                      className="form-control"
                      id="defaultFormControlInput"
                      placeholder="Slider sort"
                      name="sort"
                      value={inputs.sort}
                      onChange={handleInputChange}
                    />
                  </div>
      

                  <div className="mb-3">
                    <label htmlFor="exampleFormControlSelect1" className="form-label">
                      Service Status
                    </label>
                    <select
                      className="form-select"
                      id="exampleFormControlSelect1"
                      aria-label="Default select example"
                      name="status"
                      value={inputs.status}
                      onChange={handleInputChange}
                    >
                      <option>select Status</option>
                      <option value="active">active</option>
                      <option value="inactive">Inactive</option>
                    </select>
                  </div>

                  <div className="mb-3 text-center">
                    <button type="submit" className="btn btn-primary">
                      submit
                    </button>
                  </div>


                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditSlider;

