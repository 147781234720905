import React, { useState, useEffect } from 'react';
import { apibaseURl, showSuccessToast, showErrorToast,fileupload,filebasepath,} from "../../../Function";
import { Link, useNavigate, useParams } from 'react-router-dom';
import { IoMdArrowRoundBack } from 'react-icons/io';
import axios from 'axios';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Swal from 'sweetalert2'


const EditService = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [state, setState] = useState({
    title: "",
    descrip: "",
    status: "",
    sort: "",
    service_featured: "",
    meta_keyword: "",
    meta_key_discription: "",
  });
  const [image, setImage] = useState('');
  const [headerimage, setHeaderimage] = useState('');
  const [progressCount, setprogressCount] = useState(0);
  const [progressCountheaderimage, setprogressCountheaderimage] = useState(0);
  // image upload api in ==================================> 
   // image upload api in ==================================>
   const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("image", file);

    try {
      const response = await axios.post(`${fileupload}addimage`, formData, {
        onUploadProgress: (data) => {
          const percentCompletedto = Math.round((data.loaded/data.total)*100);
          setprogressCount(percentCompletedto);
          if (percentCompletedto == 100) {
            setTimeout(()=>{
            setprogressCount(0);
          },4000)
          }
        },
      });
      if (response.status === 200 && response.data.status === true) {
        setImage(response.data.data.image);
        showSuccessToast(response?.data?.msg);
      }
    } catch (error) {
 
    }
  };

  //  service header image upload api in ================================== ================>
  const handleFileUploadheader = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("image", file);

    try {
      const response = await axios.post(`${fileupload}addimage`, formData, {
        onUploadProgress: (data) => {
          const percentCompletedto = Math.round((data.loaded/data.total)*100);
          setprogressCountheaderimage(percentCompletedto);
          if(percentCompletedto == 100) {
          setTimeout(()=>{
            setprogressCountheaderimage(0)
          },4000)
          }
        },
      });

      if (response.status === 200 && response.data.status === true) {
        setHeaderimage(response.data.data.image);
        showSuccessToast(response?.data?.msg);
    
      }
    } catch (error) {
 
    }
  };
  // single data show ===================>
  const handleFetchService = async () => {
    try {
      const response = await axios.get(`${apibaseURl}service/${id}`);
      if (response.status === 200 && response.data.status === true) {
        setState({ ...response.data.data });
        setImage(response.data.data.image);
        setHeaderimage(response.data.data.header_image);
      }
    } catch (error) {
      // console.error("An error occurred:", error);
    }
  };

  useEffect(() => {
    handleFetchService();
  }, [id]);

  // updated data ===================================>
  const handleUpdatesubmit = async (e) => {
    e.preventDefault();
    const userdata = {
      title: state.title,
      image:image,
      header_image:headerimage,
      descrip: state.descrip,
      status: state.status,
      sort: state.sort,
      service_featured: state.service_featured,
      meta_keyword: state.meta_keyword,
      meta_key_discription: state.meta_key_discription,
      id: id
    };

    try {
      const response = await axios.post(`${apibaseURl}editservice`, userdata);
      if (response.status === 200 && response.data.status === true) {
        showSuccessToast(response.data.msg);
        navigate("/admin/adminservice");
      } else {
        showErrorToast(response.data.error);
      }
    } catch (error) {
 
    }
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  };

  
  // delete api call for icon ================================>
  const handledeleteimageicon =(id)=>{
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        const connectapi = deleteApiCallforicon(id)
        connectapi.then((responses)=>{
         if(responses.status==200){
          if(responses?.data?.status == true){
            Swal.fire(
              'Deleted!',
              'Your file has been deleted.',
              'success'
            )
            handleFetchService()
          }else{
            Swal.fire(
              'Opps Something Wrong?',
              'That thing is still around?',
              'question'
            )
          }
         
         }
        })
      }
    })
   }

   const deleteApiCallforicon = async (id) => {
     const responses = await axios.post(`${apibaseURl}service/delete/icon/${id}`);
     return responses;
   }
  // delete api call for header image ================================>
  const handledeleteimage =(id)=>{
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        const connectapi = deleteApiCall(id)
        connectapi.then((responses)=>{
         if(responses.status==200){
          if(responses?.data?.status == true){
            Swal.fire(
              'Deleted!',
              'Your file has been deleted.',
              'success'
            )
            handleFetchService()
          }else{
            Swal.fire(
              'Opps Something Wrong?',
              'That thing is still around?',
              'question'
            )
          }
         
         }
        })
      }
    })
   }

   const deleteApiCall = async (id) => {
     const responses = await axios.post(`${apibaseURl}service/delete/image/${id}`);
     return responses;
   }

  return (
    <>
      <div className="container-xxl flex-grow-1 container-p-y">
        <div className="row">
          <div className="col-md-12">
            <div className="card mb-4">
              <div className="data_acrd_wrapper">
                <h5 className="card-header">Edit Service</h5>
                <Link to="/admin/adminservice" className="btn btn-primary primary_ya"><span className='class_plus'><IoMdArrowRoundBack /></span>Back</Link>
              </div>
              <div className='add_divider'> <hr /></div>

              <div className="card-body">
                <form onSubmit={handleUpdatesubmit}>
                  <div className="mb-3">
                    <label className="form-label">Service Title</label>
                    <input
                      type="text"
                      className="form-control"
                      id="defaultFormControlInput"
                      placeholder="Service Title"
                      name="title"
                      value={state.title}
                      onChange={handleInputChange}
                    />
                  </div>

                  <div className="col-md mb-3">
                    <small className="d-block">Service Featured</small>
                    <div className="form-check form-check-inline mt-3">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="service_featured"
                        id="inlineRadio1"
                        value="Yes"
                        checked={state.service_featured === "Yes"}
                        onChange={handleInputChange}
                      />
                      <label className="form-check-label" htmlFor="inlineRadio1">
                        Yes
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="service_featured"
                        id="inlineRadio2"
                        value="No"
                        checked={state.service_featured === "No"}
                        onChange={handleInputChange}
                      />
                      <label className="form-check-label" htmlFor="inlineRadio2">
                        No
                      </label>
                    </div>
                  </div>

                  <div className="mb-3">
                    <label htmlFor="exampleFormControlSelect1" className="form-label">
                      Service Status
                    </label>
                    <select
                      className="form-select"
                      id="exampleFormControlSelect1"
                      aria-label="Default select example"
                      name="status"
                      value={state.status}
                      onChange={handleInputChange}>

                      <option value="active">Active</option>
                      <option value="inactive">Inactive</option>
                    </select>
                  </div>

                  
                  <div className="mb-3">
                    <label className="form-label">Service Sort</label>
                    <input
                      type="text"
                      className="form-control"
                      id="defaultFormControlInput"
                      placeholder="Service Sort"
                      name="sort"
                      value={state.sort}
                      onChange={handleInputChange}
                    />
                  </div>

                  <div className="mb-3">
                    <label htmlFor="formFileMultiple" className="form-label">
                      Service Icon
                    </label><br/>

                    {image ?  
                       <div className='image_wapre_div_for_data_image'>
                          <img src={filebasepath + image} alt="Avatar" className=""  width="50px" /> 
                          <i class="fa fa-times bar_second_image_top" aria-hidden="true" 
                         onClick={()=>{handledeleteimageicon(id)}}></i>
                      </div>
                    :""
                    
                    }
                    
                
                    <input 
                    className="form-control"
                     type="file" 
                     id="formFileMultiple1"
                     name='serviceicon'
                     onChange={handleFileUpload} />
                    {progressCount > 0 && (
                      <div class="progress mt-2">
                        <div
                          class="progress-bar progress-bar-striped bg-danger progress-bar-animated progress_bar_height_decode"
                          role="progressbar"
                          aria-valuenow={progressCount}
                          aria-valuemin="0"
                          aria-valuemax="100"
                          style={{ width: `${progressCount}%` }}
                        >{`${progressCount}%`}</div>
                      </div>
                    )}
                  </div>

                  <div className="mb-3">
                    <label htmlFor="formFileMultiple" className="form-label">
                      Service Header Image
                    </label><br/>
                    {headerimage ? 
                      <div className='image_wapre_div_for_data_image'>
                      <img src={filebasepath + headerimage} alt="Avatar" className=""  width="200px" /> 
                      <i class="fa fa-times bar_second_image_top" aria-hidden="true" onClick={()=>{handledeleteimage(id)}}></i>
                      </div>

                    :
                    ""
                    }
                      
                    <input
                      className="form-control"
                      type="file"
                      id="formFileMultiple2"
                      name="serviceheaderimg"
                      onChange={handleFileUploadheader} />
                  {progressCountheaderimage > 0 && (
                      <div class="progress mt-2">
                        <div
                          class="progress-bar progress-bar-striped bg-danger progress-bar-animated progress_bar_height_decode"
                          role="progressbar"
                          aria-valuenow={progressCountheaderimage}
                          aria-valuemin="0"
                          aria-valuemax="100"
                          style={{ width: `${progressCountheaderimage}%` }}
                        >{`${progressCountheaderimage}%`}</div>
                      </div>
                    )}
                  </div>

                    <div className="mb-3">
                     <label className="form-label">Service Description</label>

                        <CKEditor
                          editor={ClassicEditor}
                          data={state.descrip}
                          onChange={(event, editor) => {
                            const data = editor.getData();
                            setState((prevState) => ({
                              ...prevState,
                              descrip: data,
                            }));
                          }}
                          onReady={(editor) => {
                          }}
                          onBlur={(event, editor) => {
                          }}
                          onFocus={(event, editor) => {
                          }}
                        />
                      </div>

                  <div className="mb-3">
                    <label className="form-label">Seo Meta Keyword</label>
                    <input
                      type="text"
                      className="form-control"
                      id="defaultFormControlInput"
                      placeholder="Seo Mata Keyword"
                      name="meta_keyword"
                      value={state.meta_keyword}
                      onChange={handleInputChange}
                    />
                  </div>

                  <div className="mb-3">
                    <label className="form-label">Seo Meta Description</label>
                    <input
                      type="text"
                      className="form-control"
                      id="defaultFormControlInput"
                      placeholder="Seo Mata Description"
                      name="meta_key_discription"
                      value={state.meta_key_discription}
                      onChange={handleInputChange}
                    />
                  </div>



                  <div className="mb-3 text-center">
                    <button type="submit" className="btn btn-primary">
                      submit
                    </button>
                  </div>

                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default EditService;
